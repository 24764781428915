import {
  theme,
  JobSummaryCard,
  SearchJob,
  Button,
  getEstimatedReward,
  ProgressComponent,
} from "@project/shared";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import RightOutlined from "../assets/right.svg";
import Link from "next/link";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { fetchHomeData } from "../services/home";
import { Spin } from "antd";
import PrivateRoute from "../withPrivateRoute";
import { useContext } from "react";
import { AuthContext } from "../utils";
import { InfoIcon } from "../assets/icons";

const Wrapper = styled.div`
  padding: 24px 15.28%;
  justify-content: center;
  display: flex;
  & > div:first-child {
    margin-right: 24px;
  }
  @media (max-width: 1375px) {
    padding: 24px 120px;
  }
  @media (max-width: 1075px) {
    padding: 24px 80px;
  }
  @media (max-width: 980px) {
    padding: 24px 50px;
  }
  @media (max-width: 920px) {
    padding: 24px 26px;
  }
  @media (max-width: 768px) {
    padding: 24px 0px;
    & > div:first-child {
      display: none;
    }
  }
`;
const Container = styled.div`
  position: relative;
  flex: 1;
  max-width: 1240px;
  & > div:first-child {
    min-height: 163px;
  }
  & > div:last-child {
    margin-bottom: 0px;
  }
`;
const Content = styled.div`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 16px 16px;
  background: ${theme.base};
  margin-bottom: 24px;
  min-height: 260px;
  & .see-more {
    height: 48px;
    margin-top: 16px;
    & span {
      font-size: 16px;
      line-height: 24px;
    }
    @media (min-width: 476px) {
      display: none;
    }
  }
`;
const Title = styled.p`
  ${theme.typography.typographyLarge}
  margin-bottom: 0px;
  padding: 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    width: calc(25% - 16px);
  }
  @media (max-width: 1275px) {
    & > div {
      width: calc(33.3% - 16px);
    }
  }
  @media (max-width: 900px) {
    & > div {
      width: calc(50% - 16px);
    }
  }
  @media (max-width: 768px) and (min-width: 361px) {
    & > div:nth-child(odd)::after {
      height: calc(100% - 8px);
      position: absolute;
      right: -8px;
      width: 1px;
      background: ${theme.border};
      content: "";
    }
  }
  @media (max-width: 768px) {
    & > div {
      border-bottom: 1px solid ${theme.border};
      position: relative;
    }
  }
  @media (max-width: 370px) {
    & > div {
      width: calc(100% - 16px);
    }
  }
`;
const SearchWrapper = styled.div`
  display: block;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;
const SeeMore = styled.a`
  display: flex;
  font-size: 14px;
  color: ${theme.planeText};
  line-height: 21px;
  align-items: center;
  & svg {
    margin-left: 8px;
  }
  @media (max-width: 475px) {
    display: none;
  }
`;

const LoaderWrapper = styled.div`
  min-height: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${theme.gray7};
  white-space: break-spaces;
  margin-top: 50px;
  text-align: center;
`;

const InfoWrapper = styled.div`
  background-size: cover;
  background-color: #fae5e4;
  min-height: 133px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NewWrapper = styled.div`
  max-width: 1240px;
  @media (min-width: 1325px) {
    width: 65%;
  }
`;
const ContentWrapper = styled.div`
  margin: 1rem;
  display: flex;
  .text-content {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #a4332b;
  }
  & svg {
    margin-right: 5px;
  }
`;

const RegisterButton = styled(Button)`
  background-color: #a4332b;
  border: none;
  margin-top: 13px;
  border-radius: 0;
  :hover,
  :focus {
    background-color: #a4332b;
    opacity: 1;
  }
`;

const Home = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { user, authenticated, username } = useContext(AuthContext);

  const { data, isLoading, isError, refetch, isFetching } = useQuery<any>(
    ["fetch-homepage"],
    fetchHomeData,
    {
      enabled: user !== null,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      {authenticated && username === "" ? (
        <InfoWrapper>
          <NewWrapper>
            <ContentWrapper>
              <InfoIcon />
              <div>
                <div className="text-content">
                  <div>{t("Your profile has not been entered yet.")}</div>
                  <div>
                    {t(
                      "You will not receive any scouts from companies, so make sure your profile information is complete first!"
                    )}
                  </div>
                </div>
                <RegisterButton onClick={() => router.push("/settings")}>
                  {t("Register Your Profile")}
                </RegisterButton>
              </div>
            </ContentWrapper>
          </NewWrapper>
        </InfoWrapper>
      ) : authenticated && data?.referableCounts === 0 ? (
        <InfoWrapper>
          <NewWrapper>
            <ContentWrapper>
              <InfoIcon />
              <div>
                <div className="text-content">
                  <div>
                    {t(
                      "You have not entered the information of the companies you can introduce."
                    )}
                  </div>
                  <div>
                    {t(
                      "To ensure proper matching, please add at least 10 companies that can be referred!"
                    )}
                  </div>
                </div>
                <RegisterButton
                  onClick={() => {
                    localStorage.setItem("settingsTab", "introduce-companies");
                    router.push("/settings");
                  }}
                >
                  {t("Introduce Companies")}
                </RegisterButton>
              </div>
            </ContentWrapper>
          </NewWrapper>
        </InfoWrapper>
      ) : (
        <></>
      )}
      <Wrapper>
        <SearchWrapper>
          <SearchJob />
        </SearchWrapper>
        <Container>
          {data?.entries.length === 0 &&
            data?.newJobs.length === 0 &&
            data?.recommendedJobs.length === 0 &&
            !data?.rankings &&
            data?.scouts.length === 0 && (
              <ErrorText>{t("No data available for display.")}</ErrorText>
            )}
          {data?.progress.length !== 0 && (
            <Content>
              <Heading>
                <Title>{t("Progress")}</Title>
                {data?.totalWorkProgress > 5 && (
                  <Link href="/work-progress" passHref>
                    <SeeMore>
                      {t("See More")}
                      <RightOutlined />
                    </SeeMore>
                  </Link>
                )}
              </Heading>
              <>
                {isError ? (
                  <ErrorText>
                    {t(
                      "Some kind of error has occurred.\nWe apologize for the inconvenience, please reload the page."
                    )}
                  </ErrorText>
                ) : isLoading ? (
                  <LoaderWrapper>
                    <Spin size="large" />
                  </LoaderWrapper>
                ) : (
                  <>
                    {data?.progress.map((progress: any, index: number) => (
                      <ProgressComponent
                        key={index}
                        totalReferrals={progress.numberOfReferrals}
                        referralCount={progress.referralCount}
                        date={progress.acceptedAt}
                        jobTitle={progress.title}
                        companyName={progress.company}
                        fee={progress.fee}
                        jobID={progress.jobID}
                      />
                    ))}
                  </>
                )}
              </>
            </Content>
          )}
          {data?.entries.length !== 0 && (
            <Content>
              <Heading>
                <Title>{t("Entry Status")}</Title>
                {data?.totalEntries > 4 && (
                  <Link href="/entries" passHref>
                    <SeeMore>
                      {t("See More")}
                      <RightOutlined />
                    </SeeMore>
                  </Link>
                )}
              </Heading>
              {isError ? (
                <ErrorText>
                  {t(
                    "Some kind of error has occurred.\nWe apologize for the inconvenience, please reload the page."
                  )}
                </ErrorText>
              ) : isLoading ? (
                <LoaderWrapper>
                  <Spin size="large" />
                </LoaderWrapper>
              ) : (
                <Row>
                  {data?.entries.map((info: any, index: number) => (
                    <JobSummaryCard
                      key={index}
                      id={info.job.id}
                      image={info.job.details1ImageURL}
                      job_title={info.job.title}
                      tag_type={info.job.planCategory}
                      amount={getEstimatedReward(info.job.estimatedFee)}
                      period={info.job.publishStartAt}
                      company_name={info.job.companyName}
                      created_time={info.job.created_at}
                      saved={info.job.isFavorite}
                      afterSave={refetch}
                    />
                  ))}
                </Row>
              )}
              {data?.totalEntries > 4 && (
                <Button
                  type="ghost"
                  block
                  className="see-more"
                  onClick={() => router.push("/entries")}
                >
                  {t("See More")}
                </Button>
              )}
            </Content>
          )}
          {data?.scouts.length !== 0 && (
            <Content>
              <Heading>
                <Title>{t("Scout")}</Title>
                {data?.totalScouts > 4 && (
                  <Link href="/scouts" passHref>
                    <SeeMore>
                      {t("See More")}
                      <RightOutlined />
                    </SeeMore>
                  </Link>
                )}
              </Heading>
              {isError && (
                <ErrorText>
                  {t(
                    "Some kind of error has occurred.\nWe apologize for the inconvenience, please reload the page."
                  )}
                </ErrorText>
              )}
              {isLoading ? (
                <LoaderWrapper>
                  <Spin size="large" />
                </LoaderWrapper>
              ) : (
                <Row>
                  {data?.scouts.map((info: any, index: number) => (
                    <JobSummaryCard
                      type="scout"
                      key={index}
                      id={info.jobId}
                      tag_type={info.Job.planCategory}
                      job_title={info.Job.title}
                      image={info.Job.details1ImageURL}
                      amount={getEstimatedReward(info.Job.estimatedFee)}
                      company_name={info.Job.companyName}
                      created_time={info.Job.created_at}
                      saved={info.Job.isFavorite}
                      afterSave={refetch}
                      status={info.status}
                    />
                  ))}
                </Row>
              )}
              {data?.totalScouts > 4 && (
                <Button
                  type="ghost"
                  block
                  className="see-more"
                  onClick={() => router.push("/scouts")}
                >
                  {t("See More")}
                </Button>
              )}
            </Content>
          )}
          {data?.newJobs.length !== 0 && (
            <Content>
              <Heading>
                <Title>{t("New")}</Title>
                {data?.totalNewJobs > 4 && (
                  <Link href="/search?jobType=newJobs" passHref>
                    <SeeMore>
                      {t("See More")}
                      <RightOutlined />
                    </SeeMore>
                  </Link>
                )}
              </Heading>
              {isError && (
                <ErrorText>
                  {t(
                    "Some kind of error has occurred.\nWe apologize for the inconvenience, please reload the page."
                  )}
                </ErrorText>
              )}
              {isLoading ? (
                <LoaderWrapper>
                  <Spin size="large" />
                </LoaderWrapper>
              ) : (
                <Row>
                  {data?.newJobs.map((info: any, index: number) => (
                    <JobSummaryCard
                      key={index}
                      job_title={info.title}
                      amount={getEstimatedReward(info.estimatedFee)}
                      company_name={info.companyName}
                      image={info.details1ImageURL}
                      created_time={info.created_at}
                      saved={info.isFavorite}
                      afterSave={refetch}
                      id={info.id}
                      tag_type={info.planCategory}
                    />
                  ))}
                </Row>
              )}
              {data?.totalNewJobs > 4 && (
                <Button
                  type="ghost"
                  block
                  className="see-more"
                  onClick={() => router.push("/search?jobType=newJobs")}
                >
                  {t("See More")}
                </Button>
              )}
            </Content>
          )}
          {data?.recommendedJobs.length !== 0 && (
            <Content>
              <Heading>
                <Title>{t("Recommended")}</Title>
                {data?.totalRecommendedJobs > 4 && (
                  <Link href="/search?jobType=recommendedJobs" passHref>
                    <SeeMore>
                      {t("See More")}
                      <RightOutlined />
                    </SeeMore>
                  </Link>
                )}
              </Heading>
              {isError && (
                <ErrorText>
                  {t(
                    "Some kind of error has occurred.\nWe apologize for the inconvenience, please reload the page."
                  )}
                </ErrorText>
              )}
              {isLoading ? (
                <LoaderWrapper>
                  <Spin size="large" />
                </LoaderWrapper>
              ) : (
                <Row>
                  {data?.recommendedJobs.map((info: any, index: number) => (
                    <JobSummaryCard
                      key={index}
                      job_title={info.title}
                      amount={getEstimatedReward(info.estimatedFee)}
                      company_name={info.companyName}
                      image={info.details1ImageURL}
                      created_time={info.created_at}
                      saved={info.isFavorite}
                      afterSave={refetch}
                      id={info.id}
                      tag_type={info.planCategory}
                    />
                  ))}
                </Row>
              )}
              {data?.totalRecommendedJobs > 4 && (
                <Button
                  type="ghost"
                  block
                  className="see-more"
                  onClick={() => router.push("/search?jobType=recommendedJobs")}
                >
                  {t("See More")}
                </Button>
              )}
            </Content>
          )}
          {data?.rankings && data?.rankings.length !== 0 && (
            <Content>
              <Heading>
                <Title>{t("Ranking")}</Title>
                {data?.totalRankings > 4 && (
                  <Link href="/search?jobType=rankings" passHref>
                    <SeeMore>
                      {t("See More")}
                      <RightOutlined />
                    </SeeMore>
                  </Link>
                )}
              </Heading>
              {isError && (
                <ErrorText>
                  {t(
                    "Some kind of error has occurred.\nWe apologize for the inconvenience, please reload the page."
                  )}
                </ErrorText>
              )}
              {isLoading ? (
                <LoaderWrapper>
                  <Spin size="large" />
                </LoaderWrapper>
              ) : (
                <Row>
                  {data?.rankings.map((info: any, index: number) => (
                    <JobSummaryCard
                      type="ranking"
                      rank={index + 1}
                      key={index}
                      job_title={info.title}
                      image={info.details1ImageURL}
                      amount={getEstimatedReward(info.estimatedFee)}
                      company_name={info.companyName}
                      created_time={info.created_at}
                      saved={info.isFavorite}
                      afterSave={refetch}
                      id={info.id}
                      tag_type={info.planCategory}
                      fetching={isFetching}
                    />
                  ))}
                </Row>
              )}
              {data?.totalRankings > 4 && (
                <Button
                  type="ghost"
                  block
                  className="see-more"
                  onClick={() => router.push("/search?jobType=rankings")}
                >
                  {t("See More")}
                </Button>
              )}
            </Content>
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default PrivateRoute(Home);
