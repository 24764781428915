import { TCompany, getUsersID } from "@project/shared";

export const getMemDirection = (messageData, currentUserId: string): string =>
  messageData.sender_id !== messageData.receiver_id
    ? currentUserId === messageData.sender_id
      ? "outgoing"
      : "incoming"
    : messageData.sender_role == "member"
    ? "outgoing"
    : "incoming";

export const getInboxMemDirection = (
  messageData,
  currentCompanyID: string
): string => {
  const { receiverId, senderId } = getUsersID(
    messageData.lastMessageSender,
    messageData.participantMember,
    messageData.participantCompany
  );
  return receiverId !== senderId
    ? currentCompanyID === senderId
      ? "outgoing"
      : "incoming"
    : messageData.senderRole == "member"
    ? "outgoing"
    : "incoming";
};

export const evalStatus = (
  currentUserId: string,
  message: TCompany
): boolean | undefined => {
  const direction = getInboxMemDirection(message, currentUserId) as
    | "incoming"
    | "outgoing";
  if (direction === "outgoing") {
    if (message.senderRole === "member") {
      if (message.unreadCount !== 0) {
        return false;
      }
      return message?.status === "";
    } else if (message.unreadCount !== 0) return true;
    return undefined;
  }
  if (message.unreadCount !== 0 && !message.status) return true;
  return undefined;
};
