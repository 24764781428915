import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./utils/AuthContext";
import Router from "next/router";
import styled from "styled-components";
import { Spin } from "antd";

const Spinner = styled.div`
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const { authenticated, loading, user } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (user && authenticated && Router.pathname == "/login") {
        setIsLoading(false);
        return;
      }

      if (
        user &&
        !user.emailVerified &&
        Router.pathname !== "/email-verification" &&
        user.providerData[0]?.providerId !== "facebook.com"
      ) {
        Router.push("/email-verification");
        setIsLoading(false);
        return;
      }
      if (authenticated === false) {
        if (Router.pathname === "/login") {
          setIsLoading(false);
          return;
        }
      }
      if (!authenticated && authenticated !== null) {
        if (Router.pathname === "/email-verification") {
          Router.push("/login");
          setIsLoading(false);
          return;
        }
        Router.push("/login");
      }
      setIsLoading(false);
    }, [authenticated, loading, user]);
    if (isLoading || loading) {
      return (
        <Spinner>
          <Spin size="large" />
        </Spinner>
      );
    }
    return <>{children}</>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      );
    }
  };
};

export default PrivateRoute;
