import { API } from "@project/shared";

export const fetchHomeData = async () => {
  const { data } = await API.get(`/member/home`);
  return data;
};

export const searchData = async (query) => {
  const params = {};
  params["pageSize"] = 20;
  query?.category && (params["applicationCategory"] = query.category);
  query?.page && (params["page"] = query.page);
  query?.departments && (params["departments"] = query.departments);
  query?.industries && (params["industries"] = query.industries);
  query?.areas && (params["areas"] = query.areas);
  query?.genres && (params["genres"] = query.genres);
  // query?.feeCondition && (params["feeCondition"] = query.feeCondition);
  query?.fee && (params["fee"] = query.fee);
  query?.jobType && (params["jobType"] = query.jobType);
  query?.query && (params["query"] = query?.query);
  return API.get(`/member/jobs`, { params: params });
};
