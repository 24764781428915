import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import "dayjs/locale/ja";
import i18next from "i18next";

const getLanguage = () => i18next.language || window.localStorage.i18nextLng;

dayjs.extend(relativeTime);
dayjs.locale(getLanguage());

export const checkForDifferentDay = (currentDate, previousDate) => {
  if (currentDate && previousDate) {
    const currentDateValue = new Date(currentDate.toDate());
    const previousDateValue = new Date(previousDate.toDate());

    if (
      currentDateValue.getFullYear() === previousDateValue.getFullYear() &&
      currentDateValue.getMonth() === previousDateValue.getMonth() &&
      currentDateValue.getDate() === previousDateValue.getDate()
    ) {
      return true;
    }
  }

  return false;
};
