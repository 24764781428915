/**
 * Gets files extension from file uri string
 * @param str file string
 */
export const fileExtension = (str: string): string => {
  return str.split(".").pop()?.toLowerCase() || "";
};

export const imageExtension = ["png", "jpeg", "jpg"];

/**
 * Check if passed file uri is image or not
 *
 * @param str file string
 */
export const isImage = (str: string): boolean => {
  return imageExtension.indexOf(fileExtension(str)) > -1;
};
